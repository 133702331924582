import { useEffect, useState, useMemo, useCallback } from "react";
import { usePropertyContext } from "components/pages/Account/Property/context";
import QuestionCard from "elements/QuestionCard";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import { FileData, OneZeroOrNull } from "utils/types";
import Select from "elements/Input/Select";

import { INTERNET_OPTIONS } from "../../BillReduction/BillReductionModal";
import { TextField } from "elements/Input";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import FileUpload, { FileUploadType } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";
import moment from "moment";
import client from "utils/client";

export const InternetReduction = () => {
	const { details, send, next, fetchPropertyDetails } = usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const [providerSecurityCode, setProviderSecurityCode] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [billProvider, setBillProvider] = useState("");

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);

	const handleSubmit = async (payload?: { value: OneZeroOrNull }) => {
		if (!details) return;
		try {
			setSubmitting(true);
			setError(false);

			const form = new FormData();

			form.append("provider", billProvider);
			form.append("category", "Internet");
			form.append("security_code", providerSecurityCode);
			form.append("birth_date", moment(birthdate).format("YYYY-MM-DD"));

			for (const { file } of filesToUpload) {
				form.append("bill", file);
			}

			await client.submitBillReductionData({
				form,
				property_id: details.id,
			});

			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const handleSkip = () => {
		next(true);
	};

	const options = INTERNET_OPTIONS.map(option => ({
		label: option,
		value: option,
	}));

	const buttonDisabled = useMemo(() => {
		return (
			!billProvider ||
			!providerSecurityCode ||
			!filesToUpload.length ||
			submitting
		);
	}, [filesToUpload.length, billProvider, providerSecurityCode, submitting]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="internet-reduction-provider"
				tooltipText=""
				question={
					<p className="bold">
						Would you like to reduce the cost of your internet bill?
					</p>
				}
				subTitle={
					"We'll negotiate directly with your service provider to reduce the price of your current internet bill while keeping everything about your plan the same."
				}>
				<Select
					className="mt-2"
					required
					placeholder="Select your provider"
					value={billProvider ?? ""}
					onChange={e => setBillProvider(e.target.value)}>
					<option value="" hidden disabled>
						Select your provider
					</option>
					{options.map(({ label, value }) => (
						<option key={label} value={value}>
							{label}
						</option>
					))}
				</Select>
			</QuestionCard>
			{!!billProvider && (
				<>
					<QuestionCard
						id="internet-reduction-credentials"
						tooltipText=""
						question={<p className="bold">Just a few things</p>}
						subTitle={
							<p>
								We use the same Savings-Or-Free Guarantee that you know and
								love! We only charge {details?.contingency}% of savings, and you{" "}
								<strong>only pay if you save.</strong>
							</p>
						}>
						<div className="bill-reduction-form-content">
							<div className="bill-reduction-split-row-2 mb-2 relative">
								<div className="position-relative">
									<TextField
										required
										label="Security Pin / Passphrase"
										placeholder="0000"
										value={providerSecurityCode}
										onChange={e => setProviderSecurityCode(e.target.value)}
									/>
									<div className="bill-reduction-highly-recommended">
										<p className="body-tiny bold">Highly Recommend</p>
									</div>
								</div>
								<div className="bill-reduction-form-right-comment">
									<p className="body-tiny denim-medium">
										These are almost always required by your provider and help
										avoid issues getting your bill reduced.
									</p>
								</div>
							</div>
							<div className="bill-reduction-split-row-2 mb-2">
								<div>
									<TextField
										label="Date of Birth"
										placeholder="MM/DD/YYYY"
										value={birthdate}
										onChange={e => {
											const formatted = formatDate(e, birthdate ?? "");
											const valid = isValidDate(formatted);

											if (valid) {
												setBirthdate(formatted);
											}
										}}
									/>
								</div>
								<div className="bill-reduction-form-right-comment">
									<p className="body-tiny denim-medium">
										Providing your date of birth for account verification
										reduces our need to contact you later if required.
									</p>
								</div>
							</div>
						</div>
					</QuestionCard>
					<QuestionCard
						id="internet-reduction-credentials"
						tooltipText=""
						question={<p className="bold">Upload a Bill</p>}>
						<FileUpload
							required
							label="Upload Your Most Recent Bill*"
							filesToUpload={filesToUpload}
							updateFilesToUpload={setFilesToUpload}
						/>
					</QuestionCard>
					<div>
						<p className="body-tiny denim_5 text-center pb-4">
							By submitting, I agree to the Ownwell Terms of Service and give
							Ownwell permission to reduce this bill on my behalf.{" "}
						</p>
						<ButtonV2
							size="large"
							className="mx-auto"
							disabled={buttonDisabled}>
							{submitting ? "Submitting..." : "Submit"}
						</ButtonV2>
					</div>
				</>
			)}
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};
