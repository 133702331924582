import { createContext, FC, useContext, useEffect, useState } from "react";

export interface IResponsiveContext {
	isSmall: boolean;
	isMedium: boolean;
	isLarge: boolean;
	isExtraLarge: boolean;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	isDesktopLg: boolean;
	isDesktopXl: boolean;
	isDesktopNavSignedIn: boolean;
}

export const ResponsiveContext = createContext<IResponsiveContext>({
	isSmall: false,
	isMedium: false,
	isLarge: false,
	isExtraLarge: false,
	isMobile: false,
	isTablet: false,
	isDesktop: false,
	isDesktopLg: false,
	isDesktopXl: false,
	isDesktopNavSignedIn: false,
});

export const ResponsiveContextProvider: FC = ({ children }) => {
	const [responsiveState, setResponsiveState] = useState<IResponsiveContext>({
		isSmall: false,
		isMedium: false,
		isLarge: false,
		isExtraLarge: false,
		isMobile: false,
		isTablet: false,
		isDesktop: false,
		isDesktopLg: false,
		isDesktopXl: false,
		isDesktopNavSignedIn: false,
	});

	const handleWindowSizeChange = () => {
		// Handle window width consistently across different browsers
		const windowWidth =
			window.innerWidth && document.documentElement.clientWidth
				? Math.min(window.innerWidth, document.documentElement.clientWidth)
				: window.innerWidth ||
				  document.documentElement.clientWidth ||
				  document.getElementsByTagName("body")[0].clientWidth;

		setResponsiveState({
			isSmall: windowWidth <= 500,
			isMedium: windowWidth <= 750,
			isLarge: windowWidth <= 1260,
			isExtraLarge: windowWidth >= 1261,
			isMobile: windowWidth < 672,
			isTablet: windowWidth < 960,
			isDesktop: windowWidth >= 960,
			isDesktopLg: windowWidth >= 1060,
			isDesktopXl: windowWidth >= 1232,
			isDesktopNavSignedIn: windowWidth >= 1113,
		});
	};

	useEffect(() => {
		handleWindowSizeChange();

		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	return (
		<ResponsiveContext.Provider value={responsiveState}>
			{children}
		</ResponsiveContext.Provider>
	);
};

export const useResponsiveContext = () => useContext(ResponsiveContext);
