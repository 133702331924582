import { Checkbox, Select, TextField } from "components";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import client from "utils/client";
import { usePropertyContext } from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { OneZeroOrNull } from "utils/types";
import { TileSelect } from "elements/TileSelect";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const heardAboutOptions = [
	{
		value: "Letter or postcard in the mail",
		label: "Letter or postcard in the mail",
	},
	{
		value: "Friend / Family",
		label: "Friend / Family",
	},
	{
		value: "Search Engine",
		label: "Search Engine (Google / Bing)",
	},
	{
		value: "Social Media",
		label: "Social Media (Facebook / Instagram / TikTok)",
	},
	{
		value: "Read about online",
		label: "Read about online",
	},
	{
		value: "Radio/Podcast",
		label: "Radio or podcast",
	},
	{
		value: "TV",
		label: "TV",
	},
	{
		value: "Other",
		label: "Other",
	},
];

const otherHeardAboutOptions = [
	{
		value: "Other - Valon",
		label: "Valon",
	},
	{
		value: "Other - Nextdoor",
		label: "Nextdoor",
	},
	{
		value: "Other - Rocket Money",
		label: "Rocket Money",
	},
	{
		value: "Other - Video/Audio",
		label: "Video/Audio",
	},
	{
		value: "Other - Yard Sign",
		label: "Yard Sign",
	},
].sort(() => {
	if (Math.random() > 0.5) {
		return 1;
	} else {
		return -1;
	}
});

const radioPodcastHeardAboutOptions = [
	{
		value: "Radio/Postcast - Matt Chernoff",
		label: "Matt Chernoff",
	},
	{
		value: "Radio/Podcast - Steak Shapiro",
		label: "Steak Shapiro",
	},
	{
		value: "Radio/Podcast - Erik Erickson",
		label: "Erik Erickson",
	},
	{
		value: "Radio/Podcast - Mark, Meylinda, and Ed",
		label: "Mark, Meylinda, and Ed",
	},
	{
		value: "Radio/Podcast - Bob and Matt",
		label: "Bob and Matt",
	},
	{
		value: "Radio/Podcast - George and Craig",
		label: "George and Craig",
	},
	{
		value: "Radio/Podcast - Hal Jay",
		label: "Hal Jay",
	},
	{
		value: "Radio/Podcast - El Feo",
		label: "El Feo",
	},
	{
		value: "Radio/Podcast - Seth Payne",
		label: "Seth Payne",
	},
	{
		value: "Radio/Podcast - Ryan Chase",
		label: "Ryan Chase",
	},
	{
		value: "Radio/Podcast - Tim Merryan",
		label: "Tim Merryan",
	},
	{
		value: "Radio/Podcast - Sergio Sanchez",
		label: "Sergio Sanchez",
	},
	{
		value: "Radio/Podcast - Mike Hardge",
		label: "Mike Hardge",
	},
];

const HowDidYouHearAboutUs = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [heardAbout, setHeardAbout] = useState<string | null>(
		propertySurveyContext.question_heardabout
	);

	const [somethingElse, setSomethingElse] = useState<string>("");

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next(true);
	};

	const handleSubmit = async () => {
		const valueToUse =
			(heardAbout?.includes("Other") ||
				heardAbout?.includes("Radio/Podcast")) &&
			!!somethingElse
				? `Other - ${somethingElse}`
				: heardAbout;
		if (valueToUse) {
			send({
				type: "update_context",
				context: { question_heardabout: valueToUse },
			});
		}

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					heardAbout: valueToUse,
				},
			});

			next();
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const randomizedHeardAboutOptions = useMemo(() => {
		return [
			...heardAboutOptions
				.filter(option => option.value.toUpperCase() !== "OTHER")
				.sort(() => {
					if (Math.random() > 0.5) {
						return 1;
					} else {
						return -1;
					}
				}),
			{
				label: "Other",
				value: "Other",
			},
		];
	}, []);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="how-did-you-hear-about-us"
				tooltipText="This information helps us understand how to reach more homeowners like you."
				question={<p className="lg bold">How did you hear about us?</p>}>
				<Select
					required
					className="mt-2"
					value={
						heardAbout?.includes("Other")
							? "Other"
							: heardAbout?.includes("Radio/Podcast")
							? "Radio/Podcast"
							: heardAbout ?? ""
					}
					onChange={e => {
						setHeardAbout(e.target.value as string | null);
					}}>
					<option value="" hidden disabled>
						Select
					</option>
					{!!propertySurveyContext.question_heardabout &&
						!heardAboutOptions
							.map(({ value }) => value)
							.includes(propertySurveyContext.question_heardabout) && (
							<option
								key={propertySurveyContext.question_heardabout}
								value={propertySurveyContext.question_heardabout}>
								{heardAbout}
							</option>
						)}
					{randomizedHeardAboutOptions.map(({ label, value }) => (
						<option key={label} value={value}>
							{label}
						</option>
					))}
				</Select>
				{heardAbout?.includes("Other") && (
					<>
						<div id="how-did-you-hear-about-us-other-options">
							<div>
								{otherHeardAboutOptions.map(({ value, label }) => (
									<Checkbox
										key={value}
										name={value}
										label={label}
										labelEl="p"
										checked={heardAbout === value}
										onChange={e => {
											setHeardAbout(value as string | null);
										}}
									/>
								))}
							</div>
						</div>
						<TextField
							value={somethingElse}
							onChange={e => setSomethingElse(e.target.value)}
							placeholder="Something else"
						/>
					</>
				)}
				{heardAbout?.includes("Radio/Podcast") && (
					<>
						<div id="how-did-you-hear-about-us-radio-podcast-options">
							<div>
								{radioPodcastHeardAboutOptions
									.slice(0, 6)
									.map(({ value, label }) => (
										<Checkbox
											key={value}
											name={value}
											label={label}
											labelEl="p"
											checked={heardAbout === value}
											onChange={e => {
												setHeardAbout(value as string | null);
											}}
										/>
									))}
							</div>
							<div>
								{radioPodcastHeardAboutOptions
									.slice(6)
									.map(({ value, label }) => (
										<Checkbox
											key={value}
											name={value}
											label={label}
											labelEl="p"
											checked={heardAbout === value}
											onChange={e => {
												setHeardAbout(value as string | null);
											}}
										/>
									))}
							</div>
						</div>

						<TextField
							value={somethingElse}
							onChange={e => setSomethingElse(e.target.value)}
							placeholder="Something else"
						/>
					</>
				)}
			</QuestionCard>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<ButtonV2
				size="large"
				disabled={!heardAbout || submitting}
				className="mx-auto"
				type="submit">
				Continue
			</ButtonV2>
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default HowDidYouHearAboutUs;
