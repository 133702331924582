import BbbReviews from "elements/BbbReviews";
import GoogleReviews from "elements/GoogleReviews";
import Link from "next/link";
import { FC, memo } from "react";
import { INavLink } from "utils/types";
import LogoReverse from "public/logo-reverse.svg";
import { Label } from "components";
import Grid from "elements/Grid";
import FacebookOutline from "public/facebook-outline.svg";
import TwitterOutline from "public/twitter-outline.svg";
import LinkedinOutline from "public/linkedin-outline.svg";
import YoutubeOutline from "public/youtube-outline.svg";
import InstagramOutline from "public/instagram-outline.svg";
import { useRouter } from "next/router";
import { LanguagePickerSwitch } from "elements/LanguagePicker/LanguagePickerSwitch";

interface ISocialLink {
	href: string;
	Icon: any;
	descriptiion: string;
}

const socialLinks: ISocialLink[] = [
	{
		href: "https://www.facebook.com/OwnwellOfficial",
		Icon: FacebookOutline,
		descriptiion: "Facebook",
	},
	{
		href: "https://www.linkedin.com/company/ownwell",
		Icon: LinkedinOutline,
		descriptiion: "LinkedIn",
	},
	{
		href: "https://www.instagram.com/ownwellofficial/",
		Icon: InstagramOutline,
		descriptiion: "Instagram",
	},
	{
		href: "https://twitter.com/OwnwellOfficial",
		Icon: TwitterOutline,
		descriptiion: "Twitter",
	},
	{
		href: "https://www.youtube.com/channel/UCP2qwP9i1_cQ-BGN6OcNnGQ",
		Icon: YoutubeOutline,
		descriptiion: "Youtube",
	},
];

const navLinks: Record<string, INavLink[]> = {
	Company: [
		{ label: "About", href: "/about" },
		{ label: "Pricing", href: "/pricing" },
		// { label: "Team", href: "/team" },
		{ label: "Careers", href: "/careers" },
		{ label: "Become a Partner", href: "/partners" },
	],
	Resources: [
		{ label: "Case Studies", href: "/resources#casestudies-posts" },
		{ label: "Blog", href: "/resources#blog-posts" },
		{ label: "Testimonials", href: "/testimonials" },
		{ label: "Templates", href: "/templates" },
	],
	Support: [{ label: "Help Center", href: "/help" }],
	States: [
		{
			label: "Texas",
			href: "/resources/texas-property-taxes-101",
		},
		{ label: "Illinois", href: "/IL" },
		{ label: "Florida", href: "/resources/florida-property-taxes-101" },
		{ label: "Georgia", href: "/resources/georgia-property-taxes-101" },
		{ label: "California", href: "/resources/california-property-taxes-101" },
		{ label: "Washington", href: "/resources/washington-property-taxes-101" },
		{ label: "New York", href: "/NY" },
	],
};

const NavLinkColumn: FC<{ links: INavLink[] }> = ({ links }) => {
	const { pathname } = useRouter();
	if (pathname.startsWith("/appeal") && pathname !== "/appeals") return null;
	return (
		<div className="flex-column footer-nav-link-column">
			{links.map(({ label, href }) => (
				<Link key={label} href={href} className="footer-nav-link">
					{label}
				</Link>
			))}
		</div>
	);
};

const NavLinks = () => {
	return (
		<>
			{Object.keys(navLinks).map(key => (
				<div className="footer-nav-link-column-container" key={key}>
					<Label className="footer-nav-link-column-title">{key}</Label>
					<NavLinkColumn links={navLinks[key]} />
				</div>
			))}
		</>
	);
};

const SocialLinks: FC = () => {
	return (
		<div id="footer-social-links">
			{socialLinks.map(({ href, Icon, descriptiion }) => (
				<div className="footer-social-link" key={href}>
					<a href={href} aria-label={descriptiion}>
						<Icon />
					</a>
				</div>
			))}
		</div>
	);
};

const FooterDisclaimer = () => {
	return (
		<div id="site-footer-disclaimer">
			<p className="sm">
				<Link href="/privacy">Privacy Policy</Link>
				<span>{" | "}</span>
				<Link href="/terms">Terms of Service</Link>
				<span>{" | "}</span>
				<Link href="/accessibility">Accessibility</Link>
				<span>{" | "}</span>
				<Link href="/advertising-disclosures">Disclosures</Link>
			</p>
			<p className="sm">
				<span>© 2024 Ownwell, Inc.</span> <span>All rights reserved.</span>
			</p>
		</div>
	);
};

//TODO: Put SocialLinks back when they have actual URLS;
const DesktopFooter = () => (
	<>
		<NavLinks />
		<div id="footer-title-section">
			<LogoReverse
				height="100%"
				width="100%"
				id="footer-logo"
				aria-label="Ownwell"
			/>
			<SocialLinks />
		</div>
		<div id="footer-reviews">
			<div id="footer-reviews-inner">
				<GoogleReviews noLink />
				<BbbReviews noLink />
			</div>
		</div>
		<FooterDisclaimer />
	</>
);

const Footer = () => {
	const { pathname } = useRouter();

	const appealFlowOpen =
		pathname.startsWith("/appeal") && pathname !== "/appeals";

	return (
		<div className="flex-column" id="site-footer">
			{!appealFlowOpen && (
				<Grid>
					<DesktopFooter />
				</Grid>
			)}
		</div>
	);
};

export default memo(Footer);
