import { FC, SelectHTMLAttributes } from "react";
import Label from "./Label";

interface SelectProps {
	label?: string;
	labelEl?: FC;
	labelBeside?: boolean;
	containerClassName?: string;
}

const Select: FC<SelectProps & SelectHTMLAttributes<HTMLSelectElement>> = ({
	label,
	labelBeside = false,
	labelEl: UserLabelEl,
	containerClassName,
	required,
	children,
	...inputProps
}) => {
	const LabelEl = UserLabelEl || Label;

	return (
		<div
			className={containerClassName}
			style={{
				display: "flex",
				flexDirection: labelBeside ? "row" : "column",
			}}>
			{label && (
				<LabelEl className="input-label" required={required}>
					{label}
				</LabelEl>
			)}
			<div className="select-field-input-wrapper">
				<select
					className={
						(label ? " with-label" : "") + (inputProps.value ? "" : " empty")
					}
					required={required}
					placeholder={undefined}
					{...inputProps}>
					{inputProps.placeholder ? (
						<option hidden disabled value="placeholder">
							{inputProps.placeholder}
						</option>
					) : null}
					{children}
				</select>
			</div>
		</div>
	);
};

export default Select;
