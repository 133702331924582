import { displayMortgageTermInYearsOrMonths } from "utils/account";

export const bedroomOptions = Array(97)
	.fill("")
	.map((_, index) => {
		const value = index;
		return (
			<option key={"bedroom-" + index} value={value}>
				{value}
			</option>
		);
	});

export const bathroomOptions = Array(60)
	.fill("")
	.map((_, index) => {
		const value = 0.25 + index * 0.25 == 15 ? "15+" : 0.25 + index * 0.25;
		return (
			<option key={"bathroom-" + index} value={value}>
				{value}
			</option>
		);
	});

const curYear = new Date().getFullYear();
const since1900 = curYear - 1900;

export const yearOptions = Array(since1900)
	.fill("")
	.map((_, i) => (
		<option key={"year-" + i} value={curYear - i}>
			{curYear - i}
		</option>
	));

export const garageTypeOptions = [
	"Attached Garage",
	"Built-in",
	"Detached Garage",
	"Carport",
	"Mixed",
	"None",
].map((val, i) => (
	<option key={"garage-type-" + val + i} value={val}>
		{val}
	</option>
));

export const garageSpacesOptions = Array(10)
	.fill("")
	.map((_, i) => (
		<option key={"garage-spaces-" + i} value={i}>
			{i}
		</option>
	));

export const interiorWallsOptions = [
	"Brick",
	"Concrete",
	"Drywall/Sheetrock",
	"Log",
	"Cement Board",
	"Plaster",
	"Stone",
	"Metal",
	"Unfinished",
	"Wood",
	"Plywood",
	"Paneling",
	"Vinyl",
	"Other",
].map((val, i) => (
	<option key={"interior-walls-" + val + i} value={val}>
		{val}
	</option>
));

export const airConditioningOptions = [
	"Central",
	"Evaporative Cooler",
	"Packaged Unit",
	"Window Unit",
	"None",
	"Other",
].map((val, i) => (
	<option key={"air-conditioning-" + val + i} value={val}>
		{val}
	</option>
));

export const heatOptions = [
	"Baseboard",
	"Electric",
	"Central",
	"Forced air unit",
	"Oil",
	"Floor/Wall",
	"Gravity",
	"Heat Pump",
	"Geo-thermal",
	"Hot Water",
	"Gas",
	"Wood Burning",
	"Radiant",
	"None",
	"Other",
].map((val, i) => (
	<option key={"heat-" + val + i} value={val}>
		{val}
	</option>
));

export const fireplacesOptions = Array(10)
	.fill("")
	.map((_, i) => (
		<option key={"fireplaces-" + i} value={i}>
			{i}
		</option>
	));

export const roofOptions = [
	"Asbestos",
	"Built-up",
	"Composition Shingle",
	"Concrete",
	"Metal",
	"Slate",
	"Rock / Gravel",
	"Tar & Gravel",
	"Bermuda",
	"Masonite/ Cement Shake",
	"Fiberglass",
	"Aluminum",
	"Wood Shake/ Shingles",
	"Other",
].map((val, i) => (
	<option key={"roof-" + val + i} value={val}>
		{val}
	</option>
));

export const sewerOptions = ["Municipal", "Septic", "Store", "Other"].map(
	(val, i) => (
		<option key={"sewer-" + val + i} value={val}>
			{val}
		</option>
	)
);

export const waterOptions = [
	"Cistern",
	"Municipal",
	"None",
	"Spring",
	"Well",
	"Other",
].map((val, i) => (
	<option key={"water-" + val + i} value={val}>
		{val}
	</option>
));

export const externalWallsOptions = [
	"Asbestos shingle",
	"Brick",
	"Brick veneer",
	"Block",
	"Composite",
	"Concrete",
	"Glass",
	"Log",
	"Metal",
	"Rock / Stone",
	"Stucco",
	"Tile",
	"Pre-cast concrete",
	"Wood",
	"Other",
].map((val, i) => (
	<option key={"external-walls-" + val + i} value={val}>
		{val}
	</option>
));

export const drivewayOptions = [
	"Other",
	"Asphalt",
	"Bomanite",
	"Chat",
	"Brick",
	"Concrete",
	"Paver",
	"Gravel",
	"Tile",
	"Dirt",
	"No Driveway",
].map((val, i) => (
	<option key={"driveway-" + val + i} value={val}>
		{val}
	</option>
));

export const constructionOptions = [
	"Adobe",
	"Brick",
	"Concrete",
	"Concrete Block",
	"Dome",
	"Frame",
	"Heavy",
	"Light",
	"Log",
	"Manufactured",
	"Other",
	"Masonry",
	"Metal",
	"Steel",
	"Stone",
	"Tilt-up (pre-cast concrete)",
	"Wood",
	"Mixed",
].map((val, i) => (
	<option key={"construction-" + val + i} value={val}>
		{val}
	</option>
));

export const propertyTypeOptions = [
	"Single Family Residential",
	"Townhouse",
	"Condominium",
	"Duplex",
	"Triplex",
	"Fourplex",
	"Mobile/Manufactured Home",
	"Rural/Agricultural Residence",
	"Planned Unit Development (PUD)",
	"Seasonal, Cabin, Vacation Residence",
	"Modular/Pre-fabricated Home",
	"Other",
].map(value => (
	<option key={"prop-type-" + value} value={value}>
		{value}
	</option>
));

export const storiesOptions = [
	"1",
	"1.25",
	"1.5",
	"1.75",
	"2",
	"2.25",
	"2.5",
	"2.75",
	"3",
	"3.25",
	"3.5",
	"3.75",
	"4",
	"4.25",
	"4.5",
	"4.75",
	"5",
	"5.25",
	"5.5",
	"5.75",
	"6",
	"6.5",
	"7",
	"7.5",
	"8",
	"8.5",
	"9",
	"9.5",
	"10",
].map(s => (
	<option key={"stories-" + s} value={s}>
		{s} {s === "1" ? "Story" : "Stories"}
	</option>
));

export const loanTermOptions = (customTerm?: number | null) => {
	const commonTerms = [84, 120, 180, 240, 360, 480];

	if (typeof customTerm === "number" && !commonTerms.includes(customTerm)) {
		commonTerms.push(customTerm);
	}

	return commonTerms
		.sort((a, b) => a - b)
		.map((_, index) => {
			const value = _;
			return (
				<option key={"loanterm-" + index} value={value}>
					{displayMortgageTermInYearsOrMonths(value)}
				</option>
			);
		});
};
