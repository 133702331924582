import React, { useMemo, useState } from "react";
import { usePropertyContext } from "../../context";
import QuestionCard from "elements/QuestionCard";
import { TileSelect } from "elements/TileSelect";
import client from "utils/client";
import { OneZeroOrNull } from "utils/types";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const EvidenceIntro = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next();
	};

	const handleSubmit = async (payload?: {
		question_deferred?: OneZeroOrNull;
		question_purchased?: OneZeroOrNull;
		question_income?: OneZeroOrNull;
	}) => {
		if (payload) {
			send({
				type: "update_context",
				context: {
					...(payload.question_deferred != null
						? { question_deferred: payload.question_deferred }
						: {}),
					...(payload.question_purchased != null
						? { question_purchased: payload.question_purchased }
						: {}),
					...(payload.question_income != null
						? { question_income: payload.question_income }
						: {}),
				},
			});
		}

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					deferredMaintenance: !!(payload?.question_deferred != null
						? payload.question_deferred
						: propertySurveyContext.question_deferred),
					recentPurchase: !!(payload?.question_purchased != null
						? payload.question_purchased
						: propertySurveyContext.question_purchased),
					incomeProducing: !!(payload?.question_income != null
						? payload.question_income
						: propertySurveyContext.question_income),
				},
			});
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(() => {
		return [
			{
				unselectedTileContent: "Damage or deferred maintenance",
				selectedTileContent: "Damage or deferred maintenance",
				isSelected: !!propertySurveyContext.question_deferred,
				onSelect: () => {
					handleSubmit({
						question_deferred: !!propertySurveyContext.question_deferred
							? 0
							: 1,
					});
				},
			},
			{
				unselectedTileContent: "Property purchased within last 18 months",
				selectedTileContent: "Property purchased within last 18 months",
				isSelected: !!propertySurveyContext.question_purchased,
				onSelect: () => {
					handleSubmit({
						question_purchased: !!propertySurveyContext.question_purchased
							? 0
							: 1,
					});
				},
			},
			{
				unselectedTileContent: "Income producing property with 2+ units",
				selectedTileContent: "Income producing property with 2+ units",
				isSelected: !!propertySurveyContext.question_income,
				onSelect: () => {
					handleSubmit({
						question_income: !!propertySurveyContext.question_income ? 0 : 1,
					});
				},
			},
			{
				unselectedTileContent: "None of the above",
				selectedTileContent: "None of the above",
				isSelected:
					propertySurveyContext.question_deferred === 0 &&
					propertySurveyContext.question_purchased === 0 &&
					propertySurveyContext.question_income === 0,
				onSelect: () => {
					handleSubmit({
						question_income: 0,
						question_purchased: 0,
						question_deferred: 0,
					});
				},
			},
		];
	}, [propertySurveyContext]);

	const btnDisabled = React.useMemo(() => {
		return (
			propertySurveyContext.question_deferred === null ||
			propertySurveyContext.question_purchased === null ||
			propertySurveyContext.question_income === null
		);
	}, [propertySurveyContext]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				next();
			}}>
			<QuestionCard
				id="currently-receiving-exemption"
				tooltipText="Information related to damages, income producing units and recent purchases along with documented evidence can help property owners earn a reduction on their appeal."
				question={
					<p className="lg bold">
						Do any of the following apply to your property?
					</p>
				}
				subTitle="Select all that apply.">
				<TileSelect tiles={tiles} />
			</QuestionCard>
			<ButtonV2 size="large" type="submit" disabled={btnDisabled}>
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
		</form>
	);
};

export default EvidenceIntro;
