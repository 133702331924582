import React from "react";
import { useRouter } from "next/router";

export const useOnboardingStyles = (): React.CSSProperties => {
	const router = useRouter();

	const styles = React.useMemo(() => {
		const styles: React.CSSProperties = {};

		if (router.query.onboarding) {
			styles.pointerEvents = "none";
		}

		return styles;
	}, [router.query.onboarding]);

	return styles;
};
