import React, { useState } from "react";
import { motion } from "framer-motion";

export const Toggle: React.FC<
	React.HTMLAttributes<HTMLDivElement> & {
		activeSide: "left" | "right";
		leftText?: string;
		rightText?: string;
	}
> = ({ activeSide, leftText = "Left", rightText = "Right", ...divProps }) => {
	// Calculate the x positions based on your CSS and element sizes
	const toggleVariants = {
		left: { x: 0 }, // Start position
		right: { x: "100%" }, // Adjusted to move the handle to the right side
	};

	return (
		<div
			{...divProps}
			className={
				"toggle-button" + (divProps.className ? " " + divProps.className : "")
			}>
			<motion.div
				className="toggle-handle"
				animate={activeSide}
				variants={toggleVariants}
				transition={{ type: "spring", stiffness: 700, damping: 50 }}
			/>
			<div className="text-container">
				<span
					className={"toggle-text" + (activeSide === "left" ? " active" : "")}>
					{leftText}
				</span>
				<span
					className={"toggle-text" + (activeSide === "right" ? " active" : "")}>
					{rightText}
				</span>
			</div>
		</div>
	);
};
