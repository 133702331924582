import axios from "axios";

export const SLACK_ERRORS_WEBHOOK_URL =
	"https://hooks.slack.com/services/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";

export const SLACK_ERRORS_NETWORK_WEBHOOK_URL =
	"https://hooks.slack.com/services/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";

export const SLACK_ERRORS_SUPPRESS_WEBHOOK_URL =
	"https://hooks.slack.com/services/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";

export const SLACK_PARTNER_WEBHOOK_URL =
	"https://hooks.slack.com/services/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";

export const DISCORD_HELP_CENTER_URL =
	"https://discord.com/api/webhooks/1022307334430666872/HswCbqaWBVznboStOZ8uBezqy-UqbP4Afio6VmZLdTb0d5ciLAcsgoCfjmBn4-3jFqcR";

export const TAX_PAYMENTS_WEBHOOK_URL =
	"https://hooks.slack.com/services/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";

const SLACK_INSURANCE_URL =
	"https://hooks.slack.com/services/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";

const sendWebhook = async (
	content: string,
	headers?: { [key: string]: string }
) => {
	if (process.env.NODE_ENV === "development") return;

	const url = new URL(SLACK_ERRORS_WEBHOOK_URL);

	try {
		await fetch(url.href, {
			method: "POST",
			body: JSON.stringify({ text: content }),
			headers: headers
				? headers
				: {
						"Content-Type": "application/json",
				  },
			mode: "no-cors",
		});
	} catch (err) {}
};

export const sendErrorToWebhook = async (
	error: string,
	options: {
		channel: "errors" | "errors-network" | "errors-suppress";
	} = { channel: "errors" }
) => {
	if (process.env.NODE_ENV === "development") {
		console.error("WOULD SEND TO ERROR WEBHOOK:\n", error);
		return;
	}

	const url = new URL(
		options.channel === "errors"
			? SLACK_ERRORS_WEBHOOK_URL
			: options.channel === "errors-network"
			? SLACK_ERRORS_NETWORK_WEBHOOK_URL
			: SLACK_ERRORS_SUPPRESS_WEBHOOK_URL
	);

	try {
		await fetch(url.href, {
			method: "POST",
			body: JSON.stringify({ text: error.substring(0, 1990) }),
			headers: {
				"Content-Type": "application/json",
			},
			mode: "no-cors",
		});
	} catch (err) {
		console.error("Could not send webhook");
	}
};

export const sendSignatureToWebhook = (dataurl: string, filename: string) => {
	if (process.env.NODE_ENV === "development") return;
	try {
		const arr = dataurl.split(",");
		//@ts-ignore
		const mime = arr[0]?.match(/:(.*?);/)[1];
		const bstr = window.atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		const answer = new File([u8arr], filename, { type: mime });
		const formData2 = new FormData();

		formData2.append("file", answer);

		const url = new URL(SLACK_ERRORS_WEBHOOK_URL);

		const params = {
			method: "POST",
			body: formData2,
		};

		//TODO: figure out why fetch works here but axios doesn't
		fetch(url.href, params);
	} catch (e) {}
};

export const sendWebhookToHelpCenter = async (message: string) => {
	if (process.env.NODE_ENV === "development") {
		console.error("Would send to help center:\n", message);
		return;
	}

	const url = new URL(DISCORD_HELP_CENTER_URL);

	try {
		await axios.post(
			url.href,
			{ content: message.substring(0, 1999) },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
	} catch (err) {
		console.error("Could not send help center webhook");
	}
};

export const sendWebhookToInsurance = async (message: string) => {
	if (process.env.NODE_ENV === "development") {
		console.error("Would send to Insurance webhook:\n", message);
		return;
	}

	const url = new URL(SLACK_INSURANCE_URL);

	try {
		await fetch(url.href, {
			method: "POST",
			body: JSON.stringify({ text: message.substring(0, 1990) }),
			headers: {
				"Content-Type": "application/json",
			},
			mode: "no-cors",
		});
	} catch (err) {
		console.error("Could not send insurance webhook");
	}
};

export const sendWebhookToTaxPayments = async (message: string) => {
	if (process.env.NODE_ENV === "development") {
		console.error("Would send to Tax Payments webhook:\n", message);
		return;
	}

	const url = new URL(TAX_PAYMENTS_WEBHOOK_URL);

	try {
		await fetch(url.href, {
			method: "POST",
			body: JSON.stringify({ text: message.substring(0, 1990) }),
			headers: {
				"Content-Type": "application/json",
			},
			mode: "no-cors",
		});
	} catch (err) {
		console.error("Could not send tax payments webhook");
	}
};

export default sendWebhook;
