import axios from "axios";
import { useRouter } from "next/router";
import {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import client from "utils/client";
import { ReauthorizationData } from "utils/types";

export const ReauthorizationContext = createContext<{
	reauthorizationData: ReauthorizationData[] | undefined;
	setReauthorizationData: Dispatch<
		SetStateAction<ReauthorizationData[] | undefined>
	>;
	showErr: boolean;
	setShowErr: Dispatch<SetStateAction<boolean>>;
}>({
	reauthorizationData: undefined,
	setReauthorizationData: () => {},
	showErr: false,
	setShowErr: () => {},
});

export const useReauthorizationContext = () =>
	useContext(ReauthorizationContext);

export const ReauthorizationContextProivder: FC = ({ children }) => {
	const [reauthorizationData, setReauthorizationData] =
		useState<ReauthorizationData[]>();

	const [showErr, setShowErr] = useState(false);

	const router = useRouter();

	useEffect(() => {
		if (!router.pathname.startsWith("/reauthorize")) {
			setReauthorizationData(undefined);
			return;
		}
		if (reauthorizationData) return;
		if (!router.isReady) return;

		const session = router.query.session as string;

		if (!session) {
			router.push("/");
			return;
		}

		client
			.getReauthorizationData(session)
			.then(({ data }) => {
				setReauthorizationData(data);
			})
			.catch(err => {
				console.error("ERR", err);
				if (axios.isAxiosError(err) && err.response?.status === 401) {
					setShowErr(true);
					return;
				}
				router.push("/");
			});
	}, [router.query, router.pathname, router.isReady, reauthorizationData]);

	return (
		<ReauthorizationContext.Provider
			value={{
				reauthorizationData,
				setReauthorizationData,
				showErr,
				setShowErr,
			}}>
			{children}
		</ReauthorizationContext.Provider>
	);
};

export const withReauthorizationContext = <T,>(Component: FC<T>) => {
	// eslint-disable-next-line react/display-name
	return (props: T) => {
		return (
			<ReauthorizationContextProivder>
				<Component {...props} />
			</ReauthorizationContextProivder>
		);
	};
};
